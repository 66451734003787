import { css } from '@emotion/react'
import { memo } from 'react'
import { AppLink2 } from '~/components/AppLink2'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const Stanli_Menu = memo<ReactProps>(function Stanli_Menu() {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        background-color: #fafafa;
        border-right: 1px solid #dbdfe7;
        gap: 4px;

        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 30px;
          color: #111111;

          &:hover {
            background-color: #eaeaea;
            color: #111111;
            font-size: 18px;
          }
        }
      `}
    >
      <AppLink2 href={'/heineken_template'}>即時指摽</AppLink2>
      <AppLink2 href={'/stanli/option'}>選擇權</AppLink2>
      <AppLink2 href={'/stanli/chips'}>股市儀表</AppLink2>
      <AppLink2 href={'/stanli/monitor'}>市場監控</AppLink2>
    </div>
  )
})
