import { css } from '@emotion/react'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import OptionChart from '../daddy960_opkevin/component/OptionChart'

export const stanli_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${(OptionChart.classesFill.storkFill = '#454545')}
      ${(OptionChart.classesFill.lineFill = '#ffaa00')}
      ${(OptionChart.classesFill.referenceDotFill = '#ffaa00')}
      ${(OptionChart.classesFill.gridFill = '#cccccc')}
    }
  `
}
