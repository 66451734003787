import { css } from '@emotion/react'
import React, { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import {
  meCheckHandlerAgentWeb,
  meCheckHandlerAgentWebStock,
} from '~/modules/SDK/me/meCheckHandlerAgentWeb'
import { LoginView } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/LoginView'
import { NoSubscriptView } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/NoSubscriptView'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { MediaBanner } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/MediaBanner'
import { CarouselMediaData } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/MediaData'

export const Preset_LoginPageOfCarousel = memo<
  ReactProps<{ resources: CarouselMediaData[]; opacity?: number }>
>(function LoginPageOfCarousel(props) {
  const { meFirebaseState } = useMeStore()

  const theme = useThemeStore(t => t.theme)

  // 沒有 firebaseMe 保證是沒登入，登入 firebase 但沒有訂閱相關商品表示權限不夠。
  const view = !meFirebaseState ? <LoginView /> : <NoSubscriptView />

  const viewImg = !meFirebaseState && <MediaBanner {...props} />

  const opacity = props.opacity ?? 0.8
  return (
    <div
      css={css`
        position: fixed;
        ${flex.v.allCenter};
        width: 100%;
        height: 100%;
        background-color: ${theme === 'dark'
          ? `rgba(20, 20, 20, ${opacity})`
          : `rgba(255, 255, 255, ${opacity})`};

        z-index: 1300;
      `}
    >
      {viewImg}
      <div
        css={css`
          margin-top: 0px;
          z-index: 1500;
          position: relative;
        `}
      >
        {view}
      </div>
    </div>
  )
})
