import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { proxy, useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { store } from '~/pages/heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { Pivots } from '../component/Pivots'

const symbolFutures = [
  'TX-1',
  'TWN-1',
  'YM-1',
  'NQ-1',
  'ES-1',
  'RTY-1',
  'FDX-1',
  'NK-1',
  'HSI-1',
  'CN-1',
  'CL-1',
  'GC-1',
  'DX-1',
]

export const stanliStore = proxy({
  symbolList: 'tw50' as 'tw50' | 'futures',
})

export const SidePane = memo<ReactProps>(function SidePane(props) {
  const symbolTW50 = useSnapshot(staticStore.symbol.tw50).map(s => s)

  const state = useSnapshot(stanliStore).symbolList
  const symbolList = state === 'tw50' ? symbolTW50 : symbolFutures

  const charting = useSnapshot(store.charting)

  // const mainMarketValue = getIndicatorAngular(state.mainMarketValue) * 100
  // const retailMarketValue = getIndicatorAngular(state.retailMarketValue) * 100

  return (
    <styledss.container>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 160px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <CirculProgressbar value={20}>大戶力道</CirculProgressbar>
        <CirculProgressbar value={-10}>散戶力道</CirculProgressbar>
      </div>
      <div
        css={css`
          height: 216px;
          width: 100%;
          padding: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <Pivots />
      </div>

      <div
        css={css`
          height: 172px;
          width: 100%;
          padding: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <TitleSymbolQuote.Default symbol={charting.symbol} />
        <div
          css={css`
            width: 100%;
            height: calc(100% - 30px);
          `}
        >
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={14}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={14}
            priceTicksMargin={-15}
          />
        </div>
      </div>

      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 32px;
          gap: 4px;
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <OutlinedButton list='tw50'>股票商品</OutlinedButton>
        <OutlinedButton list='futures'>期貨商品</OutlinedButton>
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 588px);
          box-shadow: 0 0 6px 2px #cccccc;
          border-radius: 8px;
        `}
      >
        <SimpleQuoteListInteractWithChart
          data={symbolList}
          chart={store.charting}
        />
      </div>
    </styledss.container>
  )
})

const styledss = {
  container: styled.div`
    ${fill_vertical_cross_center};
    gap: 2px;
  `,
}

//
const OutlinedButton = memo<ReactProps<{ list: typeof stanliStore.symbolList }>>(
  function OutlinedButton(props) {
    const state = useSnapshot(stanliStore).symbolList

    const styleds = {
      container: styled.div<{ select: boolean }>`
        ${fill_horizontal_all_center};
        width: 72%;
        height: 32px;
        background-color: #fafafa;
        border-bottom: 4px solid ${props_ => (props_.select ? '#00affe' : '#ffffff')};
        cursor: pointer;
        border-radius: 4px 4px 0 0;
        &:hover {
          border-bottom: 4px solid ${props_ => (props_.select ? '#00affe' : '#f5f5f5')};
          background-color: #f5f5f5;
        }
      `,
    }

    return (
      <styleds.container
        select={state === props.list}
        onClick={() => {
          stanliStore.symbolList = props.list
        }}
      >
        {props.children}
      </styleds.container>
    )
  },
)

const CirculProgressbar = memo<ReactProps<{ value: number }>>(function CirculProgressbar(props) {
  const color = props.value > 0 ? '#ff3333' : props.value < 0 ? '#33bb33' : '#00000000'
  return (
    <CirculProgressbarStyled.Container>
      <CirculProgressbarStyled.ProgressbarContent>
        <CircularProgressbar
          value={Math.abs(props.value)}
          text={`${props.value.toFixed(1)}%`}
          circleRatio={0.75}
          strokeWidth={12}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: 'butt',
            textColor: '#111111',
            textSize: 16,
            pathColor: color,
            trailColor: '#cccccc',
          })}
        />
      </CirculProgressbarStyled.ProgressbarContent>
      <CirculProgressbarStyled.NameTitle>{props.children}</CirculProgressbarStyled.NameTitle>
    </CirculProgressbarStyled.Container>
  )
})

export const CirculProgressbarStyled = {
  Container: styled.div`
    ${flex.v.allCenter}
    width: 80%;
    height: 156px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 8px;
  `,
  ProgressbarContent: styled.div`
    ${flex.v.allCenter}
    width: 80%;
    height: 116px;
  `,
  NameTitle: styled.div`
    ${flex.v.allCenter}
    width: 60%;
    height: 28px;
    background-color: #434d6e33;
    border-radius: 5px;
    font-size: 14px;
  `,
}
