import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect, useRef } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { SideBarOptionChart } from '~/modules/investment-consultant/optionChart/SideBarOptionChart'
import {
  getOptionIntradayEndDate,
  getOptionIntradayStartDate,
} from '~/pages/daddy960_opkevin/component/optionAnalyze/getOptionContractDateTime'
import { optionAnalyzeStore } from '~/pages/daddy960_opkevin/component/optionAnalyze/optionAnalyzeStore'
import { useGetOptionContract } from '~/pages/daddy960_opkevin/component/optionAnalyze/useGetOptionContract'
import { useOptionQuote } from '~/pages/daddy960_opkevin/component/optionAnalyze/useOptionQuote'
import OptionChart from '~/pages/daddy960_opkevin/component/OptionChart'
import { OptionContractSelect } from '~/pages/daddy960_opkevin/component/OptionContractSelect'
import {
  OptionEndTradeTimeSelect,
  OptionStartTradeTimeSelect,
} from '~/pages/daddy960_opkevin/component/OptionTradeTimeSelect'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'

export const OptionChartView = memo<ReactProps>(function OptionChartView() {
  //const data = useOptionQuote().quoteData

  const state = useSnapshot(optionAnalyzeStore)

  //先設最近交易日結束區間
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const endDateTime = getOptionIntradayEndDate(intraday)

  // 這邊先一開始先拿選擇權
  /** 契約代號 */
  const defaultContract = useGetOptionContract(endDateTime).default ?? ''
  /** 近期所有契約代號 */
  const contractMonth = useGetOptionContract(endDateTime).contractMonth ?? ['']
  /** 近期契約對應的結算日 */
  const allContractSettlementDate = useGetOptionContract(endDateTime).settlementDate ?? { ['']: '' }
  /** default契約的結算日 */
  const settlementDate = allContractSettlementDate?.[state.currentContract] ?? ''

  useEffect(() => {
    // 首次render將option store基本的資訊set好
    if (defaultContract !== '') {
      optionAnalyzeStore.currentContract = defaultContract
      optionAnalyzeStore.allContractSettlementDate = allContractSettlementDate
      optionAnalyzeStore.allContract = contractMonth
    }
    //defaultContract拿到後,其他資料都會拿到,所以Dependency只放defaultContract
  }, [defaultContract])

  useEffect(() => {
    optionAnalyzeStore.currentSettlementDate = settlementDate
  }, [state.currentContract])

  return (
    <styleds.container>
      <styleds.selectContent>
        <OptionContractSelect />
        <OptionStartTradeTimeSelect />
        <OptionEndTradeTimeSelect />
      </styleds.selectContent>

      <div
        css={css`
          width: 100%;
          height: 400px;
        `}
      >
        <OptionChart.display />
      </div>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 8px;
    border-radius: 6px;
    box-shadow: 0 0 6px 4px #dddddd;
  `,
  selectContent: styled.div`
    ${fill_horizontal_cross_center};
    height: 72px;
    gap: 16px;
    & > div {
      width: 200px;
    }
  `,
}
